/*eslint-disable*/
import React, { useState, useEffect, useCallback, useMemo } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";

import Class from "@material-ui/icons/Class";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Calendar from "@material-ui/icons/CalendarToday";
import LockTwoTone from "@material-ui/icons/LockTwoTone";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import moment from 'moment';

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "assets/img/banner.svg";
import logo from "../../assets/img/myjourney-logo.png";
import api from "services/api";

const useStyles = makeStyles(signupPageStyle);

const initialState = {
  email: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  middleName: '',
  lastName: '',
  birthdate: '',
};


export default function SignUpPage({ ...rest }) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [selectOrganisation, setSelectOrganisation] = useState('yrock');
  const [selectRole, setSelectRole] = useState('mentee');
  const [] = useState();
  const [ { email, 
            password,
            confirmPassword,
            firstName,
            middleName,
            lastName,
            birthdate
          }, setInputValues ] = useState(initialState);
  const [message, setMessage] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [colorStatus, setColorStatus] = useState('danger');
  const [positions, setPositions] = useState();
  const [organisations, setOrganisations] = useState();

  const onChangeChecked = (event) => {
    setChecked(!!event.target.checked);
  };

  const onChangeSelectOrganisationChange = (event) => {
    setSelectOrganisation(event.target.value);
  };

  const onChangeSelectRoleChange = (event) => {
    setSelectRole(event.target.value);
  };

  const onChangeInput = (event) => {
    const { name, value } = event.target;
    setInputValues(prevState => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    setInputValues({...initialState});
  };

  const handleSignupSubmit =  useCallback(() => {
    const regexEmail =/^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
    
    if (!regexEmail.test(email)) {
      setIsNotification(true);
      return setMessage('Email address is not valid.');
    }

    if (!password) {
      setIsNotification(true);
      return setMessage('Password is empty.');
    }

    if (password !== confirmPassword) {
      setIsNotification(true);
      return setMessage('Password does not match.');
    }

    if (!firstName || !middleName || !lastName) {
      setIsNotification(true);
      return setMessage('firstname, middlename & lastname are required.');
    }

    if (!birthdate) {
      setIsNotification(true);
      return setMessage('Birthdate should not be empty.');
    }

    if (!moment(birthdate, 'MM/DD/YYYY', true).isValid()) {
      setIsNotification(true);
      return setMessage('Birthdate is not valid format.');
    }

    if (!checked) {
      setIsNotification(true);
      return setMessage('You need to agree with the terms and conditions first.');
    }

    const organisationValue = organisations.filter(organisation => organisation.attributes.aliasId === selectOrganisation);
    const positionValue = positions.filter(position => position.attributes.uid === selectRole);
    const generateId = crypto.randomUUID().toString('hex').split('-')[0];
    const body = {
      username: email,
      email: email,
      password: password,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      birthdate: moment(birthdate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
      userId: generateId,
      organisation: organisationValue[0].id,
      position: positionValue[0].id
    };

    api.post('/auth/local/register', body)
    .then(user => {
      setMessage('User registration successful. Please check email for confirmation.');
      setColorStatus('success');
      setIsNotification(true);
      clearState();
    })
    .catch(error => {
      setMessage(error?.response?.data?.error?.message);
      setIsNotification(true);
    });
  }, [email, 
      password, 
      firstName, 
      middleName, 
      lastName, 
      birthdate,
      message, 
      organisations, 
      positions, 
      colorStatus,
      isNotification,
      confirmPassword,
      checked,
      setIsNotification,
      setMessage,
      setColorStatus]);
  
  const handleClose = () => {
    setIsNotification(false);
    setColorStatus('danger');
    setMessage('');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    
    if (!positions) {
      api.get('/positions')
      .then(result => {
        setPositions(result['data'].data);
      });
    }

    if (!organisations) {
      api.get('/organisations')
      .then(result => {
        setOrganisations(result['data'].data);
      });
    }

    if (isNotification) {
      setTimeout(() => {
        setIsNotification(false);
      }, 5000);
    }
  }, [positions, organisations, isNotification, setIsNotification]);
  
  return (
    <div>
      <Header
        fixed
        color="transparent"
        brand={logo}
        links={<HeaderLinks dropdownHoverColor="info" />}
        changeColorOnScroll={{
          height: 300,
          color: "info"
        }}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={10}>
              <Card className={classes.cardSignup}>
                <h2 className={classes.cardTitle}>Register</h2>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={5} md={5}>
                      <InfoArea
                        className={classes.infoArea}
                        title="Management Tool"
                        description="We've created journey app for corporate or organisation profiling list needs."
                        icon={PermContactCalendar}
                        iconColor="primary"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title="Private Content"
                        description="We've developed journey to share personal content to members secure and privately."
                        icon={Class}
                        iconColor="primary"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title="Role Selection"
                        description="We define role access levels to distiguish each members participation."
                        icon={Group}
                        iconColor="info"
                      />
                      <span className={classes.roleDescription}>
                        <b>Mentee</b> - member without personal group or member. <br />
                        <b>Mentor</b> - member with atleast one member in owned group. <br />
                        <b>Lifegroup Leader</b> - member with atleast three member in owned group. <br />
                        <b>Lifecoach</b> - member that leads a large community or corporate area. <br />
                        <b>Campus Missionary</b> - member that leads multiple groups of schools or campus area. <br />
                        <b>Pastor</b> - member that leads a church, multiple groups or zones. <br />
                      </span>
                    </GridItem>
                    <GridItem xs={12} sm={5} md={5}>
                      <form className={classes.form}>
                        <CustomInput
                          name="email"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Email..."
                          }}
                          onChange={onChangeInput}
                          value={email}
                        />
                        <CustomInput
                          name="password"
                          type="password"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            placeholder: "Password..."
                          }}
                          onChange={onChangeInput}
                          value={password}
                        />
                        <CustomInput
                          name="confirmPassword"
                          type="password"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                {/* <Icon className={classes.inputAdornmentIcon}>
                                  lock_outline
                                </Icon> */}
                                <LockTwoTone className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Confirm Password..."
                          }}
                          onChange={onChangeInput}
                          value={confirmPassword}
                        />
                        <CustomInput
                          name="firstName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Face className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "First Name..."
                          }}
                          onChange={onChangeInput}
                          value={firstName}
                        />
                        <CustomInput
                          name="middleName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Face className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Middle Name..."
                          }}
                          onChange={onChangeInput}
                          value={middleName}
                        />
                        <CustomInput
                          name="lastName"
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Face className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Last Name..."
                          }}
                          onChange={onChangeInput}
                          value={lastName}
                        />
                       <InputLabel 
                          className={classes.inputLabel}
                        >
                          Birthdate
                        </InputLabel>
                        <CustomInput
                          name="birthdate"
                          value={birthdate}
                          onChange={onChangeInput}
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Calendar className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "MM/DD/YYYY"
                          }}
                        />
                        <InputLabel 
                          className={classes.inputLabel}
                        >
                          Organisation
                        </InputLabel>
                        <Select
                        value={selectOrganisation}
                        onChange={onChangeSelectOrganisationChange}
                        className={classes.selectRoot}
                        label="organisation"
                        >
                          <MenuItem
                           value="null">
                           None
                          </MenuItem>
                          <MenuItem
                           value="yrock">
                           YROCK
                          </MenuItem>
                        </Select>
                        <InputLabel 
                          className={classes.inputLabel}
                        >
                          Role
                        </InputLabel>
                        <Select
                        value={selectRole}
                        onChange={onChangeSelectRoleChange}
                        className={classes.selectRoot}
                        label="role"
                        >
                          <MenuItem
                           value="mentee">
                           Mentee
                          </MenuItem>
                          <MenuItem
                           value="mentor">
                           Mentor
                          </MenuItem>
                          <MenuItem
                           value="lifegroup-leader">
                           Lifegroup Leader
                          </MenuItem>
                          <MenuItem
                           value="lifecoach">
                           Lifecoach
                          </MenuItem>
                          <MenuItem
                           value="campus-missionary">
                           Campus Missionary
                          </MenuItem>
                          <MenuItem
                           value="pastor">
                           Pastor
                          </MenuItem>
                        </Select>
                        <FormControlLabel
                          classes={{
                            label: classes.label
                          }}
                          className={classes.termsContainer}
                          control={
                            <Checkbox
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                              checked={checked}
                              onChange={onChangeChecked}
                            />
                          }
                          label={
                            <span>
                              I agree to the{" "}
                              <a className={classes.terms}
                              target="_blank" 
                              href="https://privacyterms.io/view/eNiBk2Zx-nwGgwG4D-PJ0nOS">terms and conditions</a>.
                            </span>
                          }
                        />
                        <div className={classes.textCenter}>
                          <Button 
                           round
                           onClick={() => handleSignupSubmit()} 
                           color="primary">
                            SIGNUP
                          </Button>
                        </div>
                      </form>
                    </GridItem>                
                  </GridContainer>
                </CardBody>
                {isNotification && 
                  <SnackbarContent
                      message={
                        <span>
                          {message}
                        </span>
                      }
                      close
                      color={colorStatus}  
                      center
                      onClose={handleClose}
                   />
                 } 
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
