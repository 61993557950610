/*eslint-disable*/
import React, { useState, useCallback } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Footer from "components/Footer/Footer.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import LockTwoTone from "@material-ui/icons/LockTwoTone";

import forgotPasswordStyle from "assets/jss/material-kit-pro-react/views/forgotPasswordStyle";
import logo from "../../assets/img/myjourney-logo.png";
import Parallax from "components/Parallax/Parallax.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import api from "services/api";

const useStyles = makeStyles(forgotPasswordStyle);
const initialState = {
  password: '',
  passwordConfirmation: ''
};

export default function ForgotPasswordPage() {
  const classes = useStyles();
  const url = window.location.href;
  const [{ password, passwordConfirmation }, setForgotPassword] = useState(initialState);
  const [message, setMessage] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [colorStatus, setColorStatus] = useState('danger');

  const onChangeInput = (event) => {
    const { name, value } = event.target;
    setForgotPassword(prevState => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    setForgotPassword({...initialState});
  };

  const handleSubmitChangePassword = useCallback(() => {
    const urlParams = url.split('?')[1];
    const urlQueries = new URLSearchParams(urlParams);
    let codeValue;

    for (let urlQuery of urlQueries.entries()) {
      codeValue = urlQuery[1];
    }
    const body = {
      code: codeValue,
      password,
      passwordConfirmation
    };

    if (!codeValue) {
      setIsNotification(true);
      return setMessage('No token for reset password.');
    }

    if (!password || !passwordConfirmation) {
      setIsNotification(true);
      return setMessage('Password should not be empty.');
    }

    if (password !== passwordConfirmation) {
      setIsNotification(true);
      return setMessage('Password does not match.');
    }

    api.post('/auth/reset-password', body)
    .then(response => {
      if (response) {
        setMessage('Change password successful. You may now use your new credentials.');
        setColorStatus('success');
        setIsNotification(true);
        clearState();
      }
    })
    .catch(error => {
      setIsNotification(true);
      setMessage('Change password not successful. Invalid credentials.');
    });
  },
  [password,
   passwordConfirmation,
   message, 
   colorStatus,
   isNotification,
   setIsNotification,
   setMessage,
   setColorStatus]);

  const handleClose = () => {
    setIsNotification(false);
    setColorStatus('danger');
    setMessage('');
  };

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  // });

  return (
    <div>
      <Header
        brand={logo}
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="info"
        // changeColorOnScroll={{
        //   height: 300,
        //   color: "info"
        // }}
      />
      <div className={classes.imageBanner}>
        {/* <img  src={require("assets/img/background-computer.png")} /> */}
      </div>
      <div>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Reset Password</h2>
            <GridContainer>
              <GridItem md={6} sm={6}>
                <p>
                 You can change your password for security purpose or reset it if you already forget it.
                </p>
                <form className={classes.form}>
                <CustomInput
                  name="password"
                  type="password"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                      placeholder: "Password..."
                  }}
                  value={password}
                  onChange={onChangeInput}
                />
                <CustomInput
                  name="passwordConfirmation"
                  type="password"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses
                  }}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.inputAdornment}
                      >
                        {/* <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon> */}
                        <LockTwoTone className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    placeholder: "Confirm Password..."
                  }}
                  value={passwordConfirmation}
                  onChange={onChangeInput}
                />
                <div className={classes.textCenter}>
                  <Button 
                    round
                    onClick={() => handleSubmitChangePassword()}
                    color="primary">
                      CHANGE PASSWORD
                  </Button>
                </div>
                </form>
              </GridItem>
              <GridItem md={4} sm={4} className={classes.mlAuto}>
                <InfoArea
                  className={classes.info}
                  title="Check us on our office"
                  description={
                    <p>
                      3F Yrock center Spark place P. Tuazon Blvd. cor 10th Avenue, Cubao, Quezon City, Philippines.
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title="Contact Us"
                  description={
                    <p>
                      Joel L. Dacuma <br /> 8-257-04-69 <br /> 
                      Email: <a href= "mailto:admin@myjourneytracker.com" > admin@myjourneytracker.com </a> <br />
                      Visit our facebook page: <Button href="https://www.facebook.com/joel.dacuma" justIcon simple color="facebook">
                        <i className="fab fa-facebook" />
                      </Button>
                    </p>
                  }
                  icon={Phone}
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      {isNotification &&
        <SnackbarContent
          message={
            <span>
              {message}
            </span>
          }
          close
          color={colorStatus}  
          center
          onClose={handleClose}
        />
      }
        <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                  <a
                    href="/"
                    className={classes.block}
                  >
                    Home
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/about-us"
                    className={classes.block}
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://myjourneytracker.up.railway.app"
                    className={classes.block}
                  >
                    Portal
                  </a>
                </ListItem>
              </List>
            </div>
          </div>
        }
      />
    </div>
  );
}
