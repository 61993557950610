import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import workStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/workStyle.js";

import api from 'services/api';

const useStyles = makeStyles(workStyle);

const initialState = {
    name: '',
    email: '',
    message: ''
};

export default function SectionWork({ setIsNotificationSuccess, setIsNotificationFailed }) {
  const classes = useStyles();
  const [ { name, email, message }, setInputValues ] = useState(initialState);
  
  const onChangeInput = (event) => {
    const { name, value } = event.target;
    setInputValues(prevState => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    setInputValues({...initialState});
  };

  const handleSubmitInquiry = () => {
    const body = {
      data: {
        name,
        email,
        message
      }
    };

    if (!name || !email || !message) {
      setIsNotificationFailed(true);
      return;
    }

    api.post('/inquiries', body)
    .then(response => {
      if (response) {
        setIsNotificationSuccess(true);
        clearState();
      }
    });
  };

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={8} md={8}>
          <h2 className={classes.title}>Make an Inquiry</h2>
          <h4 className={classes.description}>
            We want to take care your digital lifecycles and business process. get in touch with use
            to connect and we'd love to help our potential customers.
          </h4>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  name="name"
                  value={name}
                  onChange={onChangeInput}
                  labelText="Enter your name"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  name="email"
                  value={email}
                  onChange={onChangeInput}
                  labelText="Enter your email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <CustomInput
                name="message"
                value={message}
                onChange={onChangeInput}
                labelText="Enter your message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              />
              <GridItem
                xs={12}
                sm={4}
                md={4}
                className={classes.mrAuto + " " + classes.mlAuto}
              >
                <Button onClick={() => handleSubmitInquiry()} color="primary">Send Inquiry</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
