import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";

import JoelDacuma from "assets/img/faces/joel.jpeg";

const useStyles = makeStyles(teamStyle);

export default function SectionTeam() {
  const classes = useStyles();
  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>Meet the Founder</h2>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem           
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a href="#pablo">
                <img src={JoelDacuma} alt="profile-pic" className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>Joel Dacuma</h4>
              <h6 className={classes.textMuted}>Founder</h6>
              <p className={classes.cardDescription}>
                A Software Engineer and innovator by profession. He conducted talks and programs
                with different organizations to equip youths and professionals in spreading awareness
                on how to improve lives using technology.
              </p>
              <p className={classes.cardDescription}>
                He loves to play basketball and teach tabletop games for hobby.
              </p>
            </CardBody>
            <CardFooter className={classes.justifyContent}>
              <Button href="https://www.linkedin.com/in/joel-dacuma-9b34aa121" justIcon simple color="linkedin">
                <i className="fab fa-linkedin" />
              </Button>
              <Button href="https://www.facebook.com/joel.dacuma" justIcon simple color="facebook">
                <i className="fab fa-facebook" />
              </Button>
              <Button href="https://www.instagram.com/joeldacuma" justIcon simple color="google">
                <i className="fab fa-instagram" />
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
