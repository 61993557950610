/*eslint-disable*/ import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";

// Sections for this page
import SectionWork from "./Sections/SectionWork.js";
import SectionProduct from "./Sections/SectionProduct.js";
import SectionFeatures from "./Sections/SectionFeatures.js";
import logo from "../../assets/img/myjourney-logo.png";

const useStyles = makeStyles(landingPageStyle);

export default function LandingPage({ ...rest }) {
  const [ isNotificatioSuccess, setIsNotificationSuccess ] = useState(false);
  const [ isNotificationFailed, setIsNotificationFailed ] = useState(false);

  React.useEffect(() => {
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    if (isNotificatioSuccess || isNotificationFailed) {
      setTimeout(() => {
        setIsNotificationSuccess(false);
        setIsNotificationFailed(false);
      }, 5000);
    }
  });
  
  const classes = useStyles();

  return (
    <div>
      <Header
        color="transparent"
        brand={logo}
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "info"
        }}
        {...rest}
      />
      <Parallax image={require("assets/img/banner.svg")} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>Helping small and medium enterprises scale up their business.</h1>
              <h4>
                We offer affordable software development services, website and mobile application development. 
                We find problems and create solutions for your needs.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div  className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionProduct />
        </div>
      </div>
      <div>
        <SectionFeatures id="features" />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionWork 
          setIsNotificationSuccess={setIsNotificationSuccess}
          setIsNotificationFailed={setIsNotificationFailed}
          />
        </div>
      </div>
      {isNotificatioSuccess && (
        <SnackbarContent
              message={
                <span>
                  Inquiry had been sent. Kindly wait for our response in your email.
                </span>
              }
              close
              color="success"  
              center
        />
        )
      }
      {isNotificationFailed && (
        <SnackbarContent
              message={
                <span>
                  Inquiry failed. Kindly fill-up all fields before submitting.
                </span>
              }
              close
              color="danger"  
              center
        />
        )
      }
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                  <a
                    href="/"
                    className={classes.block}
                  >
                    Home
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="/about-us"
                    className={classes.block}
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://myjourneytracker.up.railway.app"
                    className={classes.block}
                  >
                    Portal
                  </a>
                </ListItem>
              </List>
            </div>
          </div>
        }
      />
    </div>
  );
}
