import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Code from "@material-ui/icons/Code";
import PhoneIphone from "@material-ui/icons/PhoneIphone";
import Store from "@material-ui/icons/Store";
import Business from "@material-ui/icons/Business";
import TapAndPlay from "@material-ui/icons/TapAndPlay";
import Contacts from "@material-ui/icons/Contacts";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import computer from "assets/img/background-computer.png";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Feature START */}
      <div
        className={classes.features5}
        style={{ backgroundImage: `url(${computer})` }}
      >
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>Our Services</h2>
          </GridItem>
          <div className={classes.container}>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={PhoneIphone}
                  title="Mobile App Development"
                  description={
                    <p>
                      We sell premade mobile apps so that you can release and deploy 
                      your business idea to the market faster and easier in an affordable
                      price. 
                    </p>
                  }
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Code}
                  title="Website Development"
                  description={
                    <p>
                     We build business website and customize it to make your brand a massive success.
                     We bring creative minds for scalable solution.
                    </p>
                  }
                  iconColor="info"
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Store}
                  title="Digital Marketing"
                  description={
                    <p>
                      We promote brands to connect potential customers by proving innovative advertising, 
                      resilient marketing and powerful campaigns.                           
                    </p>
                  }
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
            <GridContainer className={classes.gridContainer}>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Business}
                  title="IT Consulting and Management"
                  description={
                    <p>
                      We support and maintain your existing applications and understand how technically
                      need to always operate your infrastructure.
                    </p>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={TapAndPlay}
                  title="Application Development"
                  description={
                    <p>
                      We customize software applications using hybrid development process such as Ionic and React
                      Native to build cross-platform technologies.
                    </p>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={4} className={classes.gridItem}>
                <InfoArea
                  vertical
                  className={classes.infoArea5}
                  icon={Contacts}
                  title="Lifecoaching Services"
                  description={
                    <p>
                      With the help of our partner entities we provide not only technologies but mentorship
                      programs and professional coaching services.
                    </p>
                  }
                />
              </GridItem>
            </GridContainer>
          </div>
        </GridContainer>
      </div>
      {/* Feature END */}
    </div>
  );
}
